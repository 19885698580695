/* latin-ext */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(../../Fonts/font-latin-ext.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(../../Fonts/font-latin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice {
  font-family: 'Audiowide';
}
/* Colors */
.buzzList {
  color: white;
}
.buzzList .iconRank {
  height: 2rem;
  width: 2rem;
}
.buzzList .iconRankDense {
  height: 1.5rem;
  width: 1.5rem;
}
.buzzList .line {
  display: flex;
}
.buzzList .line .infos {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.buzzList .line .infos .playerName {
  margin-top: 4px;
}
.buzzList .line .infos .playerPoints {
  margin-top: 4px;
  margin-right: 4px;
}
.buzzList .line .infos .score {
  display: flex;
  margin-left: 8px;
}
.buzzList .line .actions {
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
