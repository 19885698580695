/* latin-ext */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(../../Fonts/font-latin-ext.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(../../Fonts/font-latin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice {
  font-family: 'Audiowide';
}
/* Colors */
.qrModal .MuiDialog-paper {
  margin: 4px !important;
}
.qrModal .MuiDialog-paper .MuiDialogContent-root {
  padding: 0 !important;
}
.qrModal .MuiDialog-paper .MuiDialogContent-root .qrWrapper {
  padding: 8vw 8vw;
  background-color: white;
  display: flex;
  justify-content: center;
}
.qrModal .MuiDialog-paper .MuiDialogContent-root .qrWrapper .qrCode {
  width: 100%;
}
.adminPanel .icon {
  height: 2rem;
  width: 2rem;
}
.adminPanel .icon.effect {
  height: 1.5rem;
  width: 1.5rem;
}
.adminPanel .urlShare {
  display: flex;
  align-items: center;
}
.adminPanel .urlShare .urlInput {
  width: 100%;
}
.adminPanel .setting {
  color: white;
  margin-bottom: 1rem;
  display: flex;
}
.adminPanel .selectSound {
  width: 100%;
}
.adminPanel .configBuzzers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 4px;
  width: 100%;
  max-width: 450px;
  color: white;
}
.adminPanel .configBuzzers .waiting {
  width: 100%;
  text-align: center;
  font-size: 0.8rem;
  color: white;
  margin-top: 8px;
  margin-bottom: 8px;
}
.adminPanel .configBuzzers .globalColor {
  display: flex;
  margin-top: 1rem;
  align-items: center;
}
.adminPanel .configBuzzers .globalColor .MuiIconButton-root {
  margin-left: auto;
}
.adminPanel .configBuzzers .globalColor .boxColor {
  margin-top: unset;
}
.adminPanel .configBuzzers .customHeader {
  display: flex;
  justify-content: flex-end;
}
.adminPanel .configBuzzers .customList {
  overflow: auto;
  max-height: 50vh;
  padding: 4px;
}
.adminPanel .configBuzzers .customList .playerConfLine {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  padding: 0.4rem 0;
}
.adminPanel .configBuzzers .customList .playerConfLine .nameConf {
  margin-right: auto;
  max-width: 22vw;
}
.adminPanel .configBuzzers .customList .playerConfLine .textBuzzerInput {
  width: 8rem;
}
.adminPanel .validateWrapper {
  margin-top: auto;
  justify-content: center;
  display: flex;
}
.adminPanel .validateWrapper .iconCheck {
  width: 2.5rem;
  height: 2.5rem;
}
