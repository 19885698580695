/* latin-ext */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(../../Fonts/font-latin-ext.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(../../Fonts/font-latin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice,
.stepName .title {
  font-family: 'Audiowide';
}
/* Colors */
.stepName {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stepName .iconApp {
  height: 30vh;
  width: 30vh;
}
.stepName .title {
  color: white;
  font-size: 12vmin;
  margin-top: 5vh;
}
.stepName .form {
  border: 1px solid #444445;
  padding: 10vmin 5vmin;
  border-radius: 8px;
  display: flex;
  margin-top: 5vh;
  margin-bottom: 10vh;
  margin-left: 2vw;
  margin-right: 2vw;
}
.stepName .form button {
  margin: 0 2vmin;
}
