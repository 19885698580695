/* latin-ext */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(../../Fonts/font-latin-ext.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(../../Fonts/font-latin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice,
.buzzerPage .diamondPoints,
.buzzerPage .title {
  font-family: 'Audiowide';
}
/* Colors */
.buzzerPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vh 6vw;
  width: 100%;
  height: 90%;
  overflow: auto;
}
.buzzerPage .topBar {
  display: flex;
  width: 100%;
  max-width: 600px;
  margin-bottom: auto;
  justify-content: space-between;
}
.buzzerPage .diamondPoints {
  color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
}
.buzzerPage .diamondPoints .diamondIcon {
  margin-left: 1rem;
  height: 1.8rem;
  width: 1.8rem;
}
.buzzerPage .listWrapperBuzzer {
  height: 30vh;
  border: 1px solid #2d2d2d;
  border-radius: 14px;
  padding: 10px;
  width: 90%;
  max-width: 500px;
  margin-bottom: 1rem;
  overflow: auto;
}
.buzzerPage .title {
  font-size: min(10vh, 10vw);
  text-align: center;
  color: white;
}
.buzzerPage .wrapperBuzzer {
  margin-bottom: auto;
}
.buzzerPage .wrapperBuzzer .lockIcon {
  height: 3rem;
  width: 3rem;
}
