html,
body,
#root,
#swipeViewWrapper,
.react-swipeable-view-container {
  margin: 0;
  padding: 0;
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* latin-ext */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/font-latin-ext.d25e9048.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/font-latin.f5e35f46.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice {
  font-family: 'Audiowide';
}
/* Colors */
.boxColor {
  height: 30px;
  width: 30px;
  border: 2px solid black;
  border-radius: 100%;
  cursor: pointer;
  margin-top: auto;
}
.cover {
  position: absolute;
  top: 0;
  left: 100vw;
  height: 100%;
  width: 100%;
}
.popover {
  position: absolute;
  z-index: 2;
  background-color: #393939;
  border: 1px solid #2d2d2d;
  padding: 1rem;
  border-radius: 20px;
  top: 20%;
}
.popover * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* latin-ext */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/font-latin-ext.d25e9048.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/font-latin.f5e35f46.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice,
.buzzerWrapper .button a {
  font-family: 'Audiowide';
}
/* Colors */
.buzzerWrapper .button {
  position: relative;
  margin: 40px;
  cursor: pointer;
}
.buzzerWrapper .button a {
  display: flex;
  z-index: 1;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
  text-decoration: none;
  position: relative;
  text-shadow: 0px 1px 0px #000;
  -webkit-filter: dropshadow(color=#000, offx=0px, offy=1px);
  filter: dropshadow(color=#000, offx=0px, offy=1px);
  border-radius: 100%;
}
.buzzerWrapper .button a:not(.disabled):active {
  top: 20px;
}
.buzzerWrapper .button:after {
  content: '';
  height: 100%;
  position: absolute;
  border-radius: 100%;
  padding: 0.2em;
  bottom: -2em;
  left: -0.6em;
}

/* latin-ext */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/font-latin-ext.d25e9048.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/font-latin.f5e35f46.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice {
  font-family: 'Audiowide';
}
/* Colors */
.buzzList {
  color: white;
}
.buzzList .iconRank {
  height: 2rem;
  width: 2rem;
}
.buzzList .iconRankDense {
  height: 1.5rem;
  width: 1.5rem;
}
.buzzList .line {
  display: flex;
}
.buzzList .line .infos {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.buzzList .line .infos .playerName {
  margin-top: 4px;
}
.buzzList .line .infos .playerPoints {
  margin-top: 4px;
  margin-right: 4px;
}
.buzzList .line .infos .score {
  display: flex;
  margin-left: 8px;
}
.buzzList .line .actions {
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* latin-ext */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/font-latin-ext.d25e9048.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/font-latin.f5e35f46.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice {
  font-family: 'Audiowide';
}
/* Colors */
.NameTag {
  display: flex;
  align-items: flex-end;
  top: 1vh;
  left: 102vw;
  color: white;
}
.NameTag .editButton {
  min-width: 10px;
  padding: 0 6px;
}

/* latin-ext */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/font-latin-ext.d25e9048.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/font-latin.f5e35f46.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice,
.buzzerPage .diamondPoints,
.buzzerPage .title {
  font-family: 'Audiowide';
}
/* Colors */
.buzzerPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vh 6vw;
  width: 100%;
  height: 90%;
  overflow: auto;
}
.buzzerPage .topBar {
  display: flex;
  width: 100%;
  max-width: 600px;
  margin-bottom: auto;
  justify-content: space-between;
}
.buzzerPage .diamondPoints {
  color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
}
.buzzerPage .diamondPoints .diamondIcon {
  margin-left: 1rem;
  height: 1.8rem;
  width: 1.8rem;
}
.buzzerPage .listWrapperBuzzer {
  height: 30vh;
  border: 1px solid #2d2d2d;
  border-radius: 14px;
  padding: 10px;
  width: 90%;
  max-width: 500px;
  margin-bottom: 1rem;
  overflow: auto;
}
.buzzerPage .title {
  font-size: min(10vh, 10vw);
  text-align: center;
  color: white;
}
.buzzerPage .wrapperBuzzer {
  margin-bottom: auto;
}
.buzzerPage .wrapperBuzzer .lockIcon {
  height: 3rem;
  width: 3rem;
}

/* latin-ext */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/font-latin-ext.d25e9048.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/font-latin.f5e35f46.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice,
.stepName .title {
  font-family: 'Audiowide';
}
/* Colors */
.stepName {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stepName .iconApp {
  height: 30vh;
  width: 30vh;
}
.stepName .title {
  color: white;
  font-size: 12vmin;
  margin-top: 5vh;
}
.stepName .form {
  border: 1px solid #444445;
  padding: 10vmin 5vmin;
  border-radius: 8px;
  display: flex;
  margin-top: 5vh;
  margin-bottom: 10vh;
  margin-left: 2vw;
  margin-right: 2vw;
}
.stepName .form button {
  margin: 0 2vmin;
}

/* latin-ext */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/font-latin-ext.d25e9048.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/font-latin.f5e35f46.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice,
.createRoom h1 {
  font-family: 'Audiowide';
}
/* Colors */
.createRoom {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vw;
}
.createRoom h1 {
  color: white;
}
.createRoom .explain {
  color: powderblue;
  text-align: center;
  max-width: 40vmax;
}

/* latin-ext */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/font-latin-ext.d25e9048.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/font-latin.f5e35f46.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice {
  font-family: 'Audiowide';
}
/* Colors */
.adminPanel {
  display: flex;
  flex-direction: column;
  padding: 5vh 5vw;
  width: 100%;
  max-width: 500px;
  height: 90%;
  overflow: auto;
}

/* latin-ext */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/font-latin-ext.d25e9048.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/font-latin.f5e35f46.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice {
  font-family: 'Audiowide';
}
/* Colors */
.qrModal .MuiDialog-paper {
  margin: 4px !important;
}
.qrModal .MuiDialog-paper .MuiDialogContent-root {
  padding: 0 !important;
}
.qrModal .MuiDialog-paper .MuiDialogContent-root .qrWrapper {
  padding: 8vw 8vw;
  background-color: white;
  display: flex;
  justify-content: center;
}
.qrModal .MuiDialog-paper .MuiDialogContent-root .qrWrapper .qrCode {
  width: 100%;
}
.adminPanel .icon {
  height: 2rem;
  width: 2rem;
}
.adminPanel .icon.effect {
  height: 1.5rem;
  width: 1.5rem;
}
.adminPanel .urlShare {
  display: flex;
  align-items: center;
}
.adminPanel .urlShare .urlInput {
  width: 100%;
}
.adminPanel .setting {
  color: white;
  margin-bottom: 1rem;
  display: flex;
}
.adminPanel .selectSound {
  width: 100%;
}
.adminPanel .configBuzzers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 4px;
  width: 100%;
  max-width: 450px;
  color: white;
}
.adminPanel .configBuzzers .waiting {
  width: 100%;
  text-align: center;
  font-size: 0.8rem;
  color: white;
  margin-top: 8px;
  margin-bottom: 8px;
}
.adminPanel .configBuzzers .globalColor {
  display: flex;
  margin-top: 1rem;
  align-items: center;
}
.adminPanel .configBuzzers .globalColor .MuiIconButton-root {
  margin-left: auto;
}
.adminPanel .configBuzzers .globalColor .boxColor {
  margin-top: unset;
}
.adminPanel .configBuzzers .customHeader {
  display: flex;
  justify-content: flex-end;
}
.adminPanel .configBuzzers .customList {
  overflow: auto;
  max-height: 50vh;
  padding: 4px;
}
.adminPanel .configBuzzers .customList .playerConfLine {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  padding: 0.4rem 0;
}
.adminPanel .configBuzzers .customList .playerConfLine .nameConf {
  margin-right: auto;
  max-width: 22vw;
}
.adminPanel .configBuzzers .customList .playerConfLine .textBuzzerInput {
  width: 8rem;
}
.adminPanel .validateWrapper {
  margin-top: auto;
  justify-content: center;
  display: flex;
}
.adminPanel .validateWrapper .iconCheck {
  width: 2.5rem;
  height: 2.5rem;
}

/* latin-ext */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/font-latin-ext.d25e9048.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/font-latin.f5e35f46.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice {
  font-family: 'Audiowide';
}
/* Colors */
.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  width: 100%;
  height: 100%;
}
.dashboard .lockAndReset {
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-width: 600px;
}
.dashboard .lockAndReset .lockDiv {
  display: flex;
}
.dashboard .lockAndReset .lockDiv .lockIcon {
  height: 2rem;
  width: 2rem;
}
.dashboard .listWrapperDashBoard {
  height: 60vh;
  border: 1px solid #2d2d2d;
  border-radius: 14px;
  padding: 1vmin;
  width: 100%;
  max-width: 500px;
  margin-bottom: 1rem;
  overflow: auto;
}
.dashboard .iconSettings {
  width: 2.5rem;
  height: 2.5rem;
  color: silver;
}

/* latin-ext */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/font-latin-ext.d25e9048.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/font-latin.f5e35f46.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice {
  font-family: 'Audiowide';
}
/* Colors */
.fullScreen {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #464646;
  font-family: sans-serif;
  overflow: hidden;
}
.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.custom-scrollbar {
  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: #561073 transparent;
  /* Chrome */
}
.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 9px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #561073;
}

