/* latin-ext */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(../../Fonts/font-latin-ext.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(../../Fonts/font-latin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice {
  font-family: 'Audiowide';
}
/* Colors */
.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  width: 100%;
  height: 100%;
}
.dashboard .lockAndReset {
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-width: 600px;
}
.dashboard .lockAndReset .lockDiv {
  display: flex;
}
.dashboard .lockAndReset .lockDiv .lockIcon {
  height: 2rem;
  width: 2rem;
}
.dashboard .listWrapperDashBoard {
  height: 60vh;
  border: 1px solid #2d2d2d;
  border-radius: 14px;
  padding: 1vmin;
  width: 100%;
  max-width: 500px;
  margin-bottom: 1rem;
  overflow: auto;
}
.dashboard .iconSettings {
  width: 2.5rem;
  height: 2.5rem;
  color: silver;
}
