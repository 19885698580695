html,
body,
#root,
#swipeViewWrapper,
.react-swipeable-view-container {
  margin: 0;
  padding: 0;
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
