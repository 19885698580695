/* latin-ext */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(../../Fonts/font-latin-ext.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  src: url(../../Fonts/font-latin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.myPolice {
  font-family: 'Audiowide';
}
/* Colors */
.boxColor {
  height: 30px;
  width: 30px;
  border: 2px solid black;
  border-radius: 100%;
  cursor: pointer;
  margin-top: auto;
}
.cover {
  position: absolute;
  top: 0;
  left: 100vw;
  height: 100%;
  width: 100%;
}
.popover {
  position: absolute;
  z-index: 2;
  background-color: #393939;
  border: 1px solid #2d2d2d;
  padding: 1rem;
  border-radius: 20px;
  top: 20%;
}
.popover * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
